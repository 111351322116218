import React, { Component } from 'react';
import classnames from 'classnames';
import axios from '../../helper/Axios';
import {looseFocus} from '../../helper/LooseFocus';
import { NotificationManager } from 'react-notifications';
import emailList from '../../helper/FreeEmailProviders';
import {resetSessionStorage} from './../../helper/getApiBase';

export default class PartnershipForm extends Component {

    state = {
        email: '',
        phone: undefined,
        first_name: '',
        company: '',
        submitting: false,
        description: ''
    }

    // On change input values map to state
    // -----------------------------------
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    // on submit
    // ---------
    onSubmit = async (e) => {
        const { first_name, company, email, phone, description } = this.state;
        e.preventDefault();
        const domain = (email ? email.split('@')[1] : '');
        if(emailList.includes(domain)){
            NotificationManager.info("Please enter a company email.");
            return;
        }
        looseFocus();
        resetSessionStorage();
        this.setState({submitting: true});
        try {
            await axios.post('/hubspot/contact_us', {first_name, company, email, phone, description, partnership: true});
            this.setState({first_name: '', company: '', email: '', phone: null, is_active: false, description: ''});
            NotificationManager.success("Thank you we will contact you shortly");
        } catch (error) {
            if(error.response.status === 409){
                NotificationManager.success("Thank you we will contact you shortly");
                this.setState({first_name: '', company: '', email: '', phone: null, is_active: false, description: ''});
            }
            else {
                NotificationManager.error('Something went wrong');
            }
        }
        this.setState({submitting: false});
    }

    render() {
        const { submitting, first_name, company, email, phone, description } = this.state;
        return (
            <div className="mg-bottom-50">
              <form onSubmit={this.onSubmit} className="contact-form">
                <section className="modal-card-body no-overflow is-paddingless radius-10 transparent-bg">
                    <div className="columns pd-40 has-text-left">
                        <div className="column is-8 card-shadow mar-auto pd-30 white-bg">
                            <h2 className="has-text-centered has-text-dark has-text-weight-bold is-size-6">Let's get started</h2>
                            <br/>
                            <div className="columns no-margin-bottom flex-wrap">
                                <div className="field column is-4 no-pd-bottom no-margin-bottom">
                                    <label className="label is-size-7">Name*</label>
                                    <div className="control">
                                        <input className="input" type="text" name="first_name" value={first_name} onChange={this.onChange} required/>
                                    </div>
                                </div>
                                <div className="field column is-4 no-pd-bottom mg-10-bottom">
                                    <label className="label is-size-7">Company Name*</label>
                                    <div className="control">
                                        <input className="input" type="text" name="company" value={company} onChange={this.onChange} required/>
                                    </div>
                                </div>
                                <div className="field column is-4">
                                    <label className="label is-size-7">Work Email*</label>
                                    <div className="control">
                                        <input className="input" type="email" name="email" value={email} onChange={this.onChange} required/>
                                    </div>
                                </div>
                                <div className="field column is-4">
                                    <label className="label is-size-7">Phone Number*</label>
                                    <div className="control">
                                        <input className="input" type="number" name="phone" value={(phone ? phone : '')} onChange={this.onChange} required/>
                                    </div>
                                </div>
                                <div className="field column is-4">
                                    <label className="label is-size-7">How can we assist you?</label>
                                    <div className="control">
                                        <textarea className="textarea" rows="1" type="text" value={description} name="description" onChange={this.onChange}/>
                                    </div>
                                </div>
                                <div className="mg-top-10 pd-top-25 column is-4">
                                    <button type="submit" className={classnames('height-36 button is-blue nav-button is-fullwidth', {'is-loading': submitting})}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>  
            </div>
        )
    }
}
